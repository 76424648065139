import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
`;

export const StyledForm = styled.form`
  /* margin-top: 20px; */
  padding: 20px;
  border-radius: 5px;
  /* display: flex; */
  .inlineField {
    vertical-align: middle;
    margin: 5px 10px 5px 0;
  }
`;

export const ErrorMessage = styled.p`
  font-size: 11px;
  font-weight: 500;
  color: #db1212;
  margin-top: 2px;

  .iconStyle {
    font-size: 12px;
    margin-top: -3px;
    margin-right: 2px;
  }
`;
