import * as yup from 'yup';

export const schema = yup.object({
  cod_tipo_mov: yup.number().required(),
  cod_tipo_pdv: yup.number().required(),
  des_tipo: yup.string().required(),
  tipo_mov: yup.object().shape({
    label: yup.string().required(),
    value: yup.number().required(),
  }),
  tipo_operacao: yup.object().shape({
    label: yup.string().required(),
    value: yup.number().required(),
  }),
  cod_categoria: yup.number().required(),
});
export interface Options {
  value: number | undefined;
  label: string | undefined;
}
