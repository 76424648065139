import React, { useCallback, useState } from 'react';
import { GridRowParams } from '@material-ui/data-grid';

import Spinner from '@atlaskit/spinner';
import { toast } from 'react-toastify';
import Search from '~/components/Search';

import { Container } from './styles';
import FormDefault from '~/components/FormDefault';
import api from '~/services/api';
import { useForm } from 'react-hook-form';
import { InputNumber2, InputText } from '~/components/NovosInputs';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './Validations';

const TipoItem: React.FC = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(true);

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  /**
   * Reseta form para criação de novo registro
   */
  const resetFormData = useCallback(() => {
    setUpdate(false);
    reset();
    setValue('cod_tipo_item', undefined);
    setValue('des_numero_tipo', '');
    setValue('num_tipo_item', '');
  }, [reset, setValue]);
  const onRowClick = async (param: GridRowParams) => {
    const { row } = param;

    const { cod_tipo_item, des_tipo_item, num_tipo_item } = row;

    setValue('cod_tipo_item', cod_tipo_item);
    setValue('des_numero_tipo', des_tipo_item);
    setValue('num_tipo_item', num_tipo_item);

    setUpdate(true);
    setLoader(false);
    setShowSearch(false);
  };

  const onSave = handleSubmit(async (dataForm) => {
    if (isUpdate) {
      const data2 = await api.put(`/tipo-item/${dataForm?.cod_tipo_item}`, {
        des_numero_tipo: dataForm.des_numero_tipo,
        num_tipo_item: dataForm.num_tipo_item,
      });
      if (data2.data.success) {
        toast.success(data2.data.message);
        resetFormData();
        setShowSearch(true);
        return;
      }
      toast.warning(data2.data.message);
      setLoader(false);
      return;
    }
    const { data } = await api.post('/tipo-item', {
      des_numero_tipo: dataForm.des_numero_tipo,
      item_numero_tipo: dataForm.num_tipo_item,
    });
    if (data.success) {
      toast.success(data.message);
      resetFormData();
      setShowSearch(false);
    }
  });

  const handleDelete = async () => {
    const { cod_tipo_item } = getValues();
    const res = await api.delete(`/tipo-item/${cod_tipo_item}`);
    const { success, message } = res.data;
    if (!success) throw new Error(message);
    resetFormData();
    setShowSearch(true);
    toast.success(message);
  };

  const onNew = () => {
    resetFormData();
    setUpdate(false);
  };

  if (loader) {
    return (
      <Container>
        <div className="w-100 h-100 d-flex justify-conten-center align-items-center">
          <Spinner />
        </div>
      </Container>
    );
  }

  return (
    <Container>
      {showSearch && (
        <Search
          newData={() => setShowSearch(false)}
          onRowClick={onRowClick}
          codTela={181}
        />
      )}

      {!showSearch && (
        <>
          <FormDefault
            codTela={181}
            title="Cadastro de Tipo de Item"
            onSave={onSave}
            codigoRegistro={[
              {
                value: getValues('cod_tipo_item'),
                des_campo: 'Código',
              },
            ]}
            onCancel={() => {
              resetFormData();
              setShowSearch(true);
            }}
            isUpdate={isUpdate}
            onNew={() => onNew()}
            onDelete={handleDelete}
            onClearFields={() => resetFormData()}
            onReturnSearch={() => {
              setShowSearch(true);
              setUpdate(false);
              resetFormData();
            }}
            showSwitch={false}
          >
            <div className="row">
              <div className="col-sm-12 col-md-2">
                <InputNumber2
                  label="Número"
                  name="num_tipo_item"
                  maxLength={3}
                  min={0.0}
                  control={control}
                  max={999}
                  placeholder="0"
                  register={register}
                  isError={!!errors.num_tipo_item}
                  onChange={(event: any) => {
                    if (event.target.value.length <= 3) {
                      setValue('num_tipo_item', event.target.value);
                    }
                  }}
                />
              </div>
              <div className="col-sm-12 col-md-10">
                <InputText
                  label="Descrição"
                  maxLength={50}
                  placeholder="Informe a Descrição"
                  name="des_numero_tipo"
                  control={control}
                  register={register}
                  caseInput="upper"
                  disabled={false}
                  isError={!!errors.des_numero_tipo}
                />
              </div>
            </div>
          </FormDefault>
        </>
      )}
    </Container>
  );
};

export default TipoItem;
