import * as yup from 'yup';
import { transformAsCurrency } from '~/utils/functions';

export const schema = yup
  .object({
    name: yup.string().required(),
    age: yup.number().required().min(0).max(100).positive().integer(),
    weight: yup
      .string()
      .required()
      .test('teste', 'teste', (val) => {
        const valueToFloat = transformAsCurrency(val || 0);
        return valueToFloat < 5000;
      }),
    weight2: yup
      .string()
      .required()
      .test('teste', 'teste', (val) => {
        const valueToFloat = transformAsCurrency(val || 0);
        return valueToFloat < 5000;
      }),
    percent: yup
      .string()
      .required()
      .test((val) => {
        const valueToFloat = transformAsCurrency(val || 0);
        return valueToFloat <= 100;
      }),
    money: yup
      .string()
      .required()
      .test((val) => {
        const valueToFloat = transformAsCurrency(val || 0);
        return valueToFloat <= 999999999;
      }),
    num_cpf: yup
      .string()
      .required()
      .test((value) => {
        // Remove os caracteres especiais e verifica possui 11 digitos
        value = value?.replace(/\D/g, '');
        return value?.length === 11;
      }),
    num_cnpj: yup
      .string()
      .required()
      .test((value) => {
        // Remove os caracteres especiais e verifica possui 14 digitos
        value = value?.replace(/\D/g, '');
        return value?.length === 14;
      }),
    selectApi: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    selectOptions: yup
      .object()
      .shape({
        label: yup.string(),
        value: yup.number(),
      })
      .nullable(),
    num_rg: yup
      .string()
      .required()
      .test((value) => {
        // Remove os caracteres especiais e verifica possui 9 digitos
        value = value?.replace(/[^(0-9|X|x)]/g, '');
        return value?.length === 9;
      }),
    num_ie: yup
      .string()
      .required()
      .test((value) => {
        // Remove os caracteres especiais e verifica possui 12 digitos
        value = value?.replace(/\D/g, '');
        return value?.length === 12;
      }),
    num_ncm: yup
      .string()
      .required()
      .test((value) => {
        // Remove os caracteres especiais e verifica possui 8 digitos
        value = value?.replace(/\D/g, '');
        return value?.length === 8;
      }),
  })
  .required();
