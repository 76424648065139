import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  /* .MuiTableHead-root {
    background-color: red !important;
  }
  .MuiTableCell-stickyHeader {
    background-color: red !important;
  } */
`;

export const ErrorMessage = styled.p`
  font-size: 11px;
  font-weight: 500;
  color: #db1212;
  margin-top: 2px;
`;
