import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Container } from './styles';
import {
  InputAsyncSelect,
  InputMaskCnpj,
  InputMaskCpf,
  InputMoney,
  InputPercent,
  InputPeso,
  InputSelect,
  InputText,
  InputNumber,
  InputTextArea,
  InputMaskRg,
  InputMaskIe,
  InputMaskNcm,
  InputDate,
  BuscaProduto,
} from '~/components/NovosInputs';

import { schema } from './validations';
import { Button, Col, Row } from 'react-bootstrap';
import FooterDefault from '~/components/FooterDefault';

let count = 0;
const TesteComponente: React.FC = () => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  count++;
  console.log('errors', errors);

  const onSubmit = handleSubmit((data) => console.log(data));
  const [resetCampoAlterados, setResetCampoAlterados] =
    useState<boolean>(false);
  const [resetCampo, setResetCampo] = useState<boolean>(false);

  const formBlank = {
    name: null,
    age: null,
    weight: null,
    weight2: null,
    percent: null,
    money: null,
    num_cpf: null,
    num_cnpj: null,
    num_rg: null,
  };
  const options = [
    {
      label: '01 - BANANA 1',
      value: 1,
    },
    {
      label: '02 - ABACATE 2',
      value: 2,
    },
    {
      label: '03 - ABACAXI 3',
      value: 3,
    },
    {
      label: '01 - BANANA 1',
      value: 4,
    },
    {
      label: '02 - ABACATE 2',
      value: 5,
    },
    {
      label: '03 - ABACAXI 3',
      value: 6,
    },
    {
      label: '01 - BANANA 1',
      value: 7,
    },
    {
      label: '02 - ABACATE 2',
      value: 8,
    },
    {
      label: '03 - ABACAXI 3',
      value: 9,
    },
    {
      label: '01 - BANANA 1',
      value: 10,
    },
    {
      label: '02 - ABACATE 2',
      value: 11,
    },
    {
      label: '03 - ABACAXI 3',
      value: 12,
    },
    {
      label: '01 - BANANA 1',
      value: 13,
    },
    {
      label: '02 - ABACATE 2',
      value: 14,
    },
    {
      label: '03 - ABACAXI 3',
      value: 15,
    },
    {
      label: '01 - BANANA 1',
      value: 16,
    },
    {
      label: '02 - ABACATE 2',
      value: 17,
    },
    {
      label: '03 - ABACAXI 3',
      value: 18,
    },
    {
      label: '01 - BANANA 1',
      value: 19,
    },
    {
      label: '02 - ABACATE 2',
      value: 20,
    },
    {
      label: '03 - ABACAXI 3',
      value: 21,
    },
    {
      label: '01 - BANANA 1',
      value: 22,
    },
    {
      label: '02 - ABACATE 2',
      value: 23,
    },
    {
      label: '03 - ABACAXI 3',
      value: 24,
    },
  ];

  return (
    <Container>
      <h1>{count}</h1>
      <form onSubmit={onSubmit} noValidate>
        <Row>
          <Col sm={12} md={3}>
            <InputText
              label="Uncontrolled Text Input"
              maxLength={50}
              placeholder="Uncontrolled Text Input"
              name="name"
              register={register}
              disabled={false}
              isError={!!errors.name}
            />
          </Col>
          <Col sm={12} md={3}>
            <InputNumber
              label="Uncontrolled Number Input"
              maxLength={5}
              max={150}
              min={0}
              placeholder="0"
              name="age"
              register={register}
              disabled={false}
              isError={!!errors.age}
            />
          </Col>
          <Col sm={12} md={2}>
            <InputPeso
              label="Peso with 2 decimals"
              decimals={2}
              name="weight"
              register={register}
              control={control}
              isError={!!errors.weight}
            />
          </Col>
          <Col sm={12} md={2}>
            <InputPeso
              label="Peso with 3 decimals"
              decimals={3}
              name="weight2"
              register={register}
              control={control}
              isError={!!errors.weight2}
            />
          </Col>
          <Col sm={12} md={2}>
            <InputNumber
              label="Uncontrolled Percent Input"
              max={100}
              min={0}
              maxLength={3}
              placeholder="0,00"
              step="1"
              name="percent"
              register={register}
              isError={!!errors.percent}
              showIcon
            />
          </Col>
          <Col sm={12} md={2}>
            <InputMoney
              label="Uncontrolled Money Input"
              min={0}
              decimals={2}
              name="money"
              register={register}
              isError={!!errors.money}
              showIcon
            />
          </Col>
          <Col sm={12} md={2}>
            <InputMaskCpf
              label="CPF"
              name="num_cpf"
              isError={!!errors.num_cpf}
              control={control}
              register={register}
            />
          </Col>
          <Col sm={12} md={2}>
            <InputMaskCnpj
              label="CNPJ"
              name="num_cnpj"
              isError={!!errors.num_cnpj}
              control={control}
            />
          </Col>
          <Col sm={12} md={12}>
            <InputTextArea
              maxLength={500}
              label="Uncontrolled TextArea Input"
              name="textArea"
              register={register}
              isError={!!errors.textArea}
              control={control}
            />
          </Col>
          <Col sm={12} md={6}>
            <InputAsyncSelect
              label="Select Input"
              maxLength={50}
              placeholder="Selecione..."
              name="selectApi"
              register={register}
              isError={!!errors.selectApi}
              control={control}
              changeSelected={(selected) => setValue('selectApi', selected)}
              api={{
                route: '/produto-paginado',
                method: 'post',
                fields: ['cod_produto', 'des_produto'],
              }}
            />
          </Col>
          <Col sm={12} md={6}>
            <InputSelect
              label="Select Input"
              maxLength={50}
              placeholder="Selecione..."
              name="selectOptions"
              register={register}
              isError={!!errors.selectOptions}
              control={control}
              options={options}
              changeSelected={(selected) => setValue('selectOptions', selected)}
            />
          </Col>

          <Col sm={12} md={2}>
            <InputMaskRg
              label="RG"
              name="num_rg"
              isError={!!errors.num_rg}
              control={control}
            />
          </Col>
          <Col sm={12} md={2}>
            <InputMaskIe
              label="IE"
              name="num_ie"
              isError={!!errors.num_ie}
              control={control}
            />
          </Col>
          <Col sm={12} md={2}>
            <InputMaskNcm
              register={register}
              label="NCM"
              name="num_ncm"
              isError={!!errors.num_ncm}
              control={control}
            />
          </Col>
          <Col sm={12} md={2}>
            <InputDate
              register={register}
              label="teste Date"
              name="dta_test"
              isError={!!errors.dta_test}
              control={control}
            />
          </Col>
          <Col sm={12} md={12} className="d-flex gap-2">
            <Button type="submit" className="mt-4 mr-4">
              Testar
            </Button>
            <Button
              type="button"
              variant="danger"
              className="mt-4"
              onClick={() => {
                setResetCampoAlterados(true);
                reset(formBlank);
                setResetCampo(true);
                setTimeout(() => {
                  setResetCampoAlterados(false);
                }, 20);
              }}
            >
              reset
            </Button>
          </Col>
          <Col sm={12} md={12}>
            <BuscaProduto
              label="Buscar Produto"
              placeholder="Busque por Código, GTIN ou Descrição"
              name="busca_produto"
              register={register}
              isError={!!errors.busca_produto}
              control={control}
              buscaNasLojas={[1]}
              buscaItensInativos={false}
              resetCamposAlterados={resetCampoAlterados}
              clearCampoBusca={resetCampo}
              customOptions={{
                hideBuscarPor: true,
                buscarPor: [
                  'Produto',
                  'Departamento',
                  'Fornecedor',
                  'Check-List',
                  'Alterados',
                ],
                buscaPadrao: 'Produto',
                showSelecionarItensContendo: true,
              }}
              setValue={setValue}
              getProduct={(selected: any) => {
                setResetCampo(false);
                console.log(selected);
              }}
              style={{ width: '100%' }}
            />
          </Col>
        </Row>
        <br />
        <FooterDefault codTela={18} />
      </form>
    </Container>
    // </HotKeys>
  );
};

export default TesteComponente;
